import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';


import ExpressShippingImg from 'images/blog-images/express-shipping.png';
import ShippingDestinationImg from 'images/blog-images/shipping-destination-img.png';
import PackagingImg from 'images/blog-images/packaging-materials.png';
import ShippingStrategyFeatured from 'images/featured-image/shipping-strategy-for-ecommerce-featured.png';
import FreeDeliveryImg from 'images/blog-images/free-delivery-image.png';



interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ShippingStrategyIMG {
      file(relativePath: { eq: "shipping-strategy-for-ecommerce.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipping strategy for ecommerce"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Shipping Strategy for Ecommerce | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-shipping-strategy" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Shipping Strategy for Ecommerce",
              content: intl.formatMessage({ id: "blog-post-meta-title-shipping-strategy" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: ShippingStrategyFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="shipping-strategy-for-ecommerce"
        title="Shipping Strategy for Ecommerce"
        date="2020-09-18"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              Achieving a successful eCommerce strategy is not only about providing cheap shipping and giving discounts, but it plays a significant role in your business success. Establishing an effective eCommerce shipping strategy in place is one of the critical points that can elevate your online business to new heights. 
            </p>

            <p>
              A solid shipping strategy for eCommerce is what sets apart a great to a good entrepreneur.
            </p>

            <p>
              Establishing an effective eCommerce shipping strategy is one of the critical points that can elevate your online business to new heights. A recent study shows that:
            </p>

            <UnorderedList>
              <li>
                <a href="https://www.shopify.com.ph/blog/shipping-strategy" target="_blank" rel="noreferrer">44% of customers</a> abandons their shopping carts due to high shipping and handling costs
              </li>
              <li>
                <a href="https://www.shopify.com.ph/blog/shipping-strategy" target="_blank" rel="noreferrer">25% of shoppers</a> refuses to proceed to add the item to the cart due to the product price being higher than they’re willing to play
              </li>
              <li>
                <a href="https://www.shopify.com/blog/international-shipping" target="_blank" rel="noreferrer">18% of shoppers</a> abandons their checkout because of slow delivery service
              </li>
              <li>
                <a href="https://jilt.com/blog/ecommerce-cart-abandonment-demystified/" target="_blank" rel="noreferrer">44% of customers</a> abandons their shopping carts because of no cheap shipping option
              </li>
            </UnorderedList>

            <p>
              It only shows that a solid shipping strategy for eCommerce sets apart a great to a good online seller.
            </p>

            <Image src={FreeDeliveryImg} className="" alt="free delivery"/>

            <h5>
              <strong>
                Ecommerce shipping methods and strategies
              </strong>
            </h5>

            <p>
              In the world of eCommerce, there is no one-strategy-works-all, since every business is unique in its own way. Formulating the right shipping strategy for your eCommerce will largely depend on your niche store, target audience, budget, products, and other possible variables that might come along the way.
            </p>

            <p>
              As a general rule, offering fast, affordable, and reliable shipping methods can help lessen your customers abandoning their shopping carts and increase your conversion rate. Here are the shipping methods that you can consider implementing to your shipping strategy:
            </p>

            <h5>
              <strong>
                2-day shipping
              </strong>
            </h5>

            <p>
              The 2-day shipping method has started becoming more popular with online shopping and became a highly requested eCommerce shipping solution. This type of exclusive shipping may seem only accessible to big companies. Still, there are ways for all kinds of businesses to offer 2-day shipping to their customers without exhausting their budget. This is a service that can often only be provided when the products are fulfilled in the same country as where the customers are located.
            </p>

            <h5>
              <strong>
                Expedited shipping
              </strong>
            </h5>

            <p>
              Expedited shipping refers to any shipping method faster than standard ground. The exact turnaround for expedited orders can vary depending on the carrier and shipping services. Offering expedited shipping can help your online store reduce shopping cart abandonment, meet customer expectations, and build customer loyalty with effective eCommerce shipping solutions.
            </p>

            <h5>
              <strong>
                International shipping
              </strong>
            </h5>

            <p>
              Wanting to expand your targeted audience to a global scale? Adding international shipping on your online business can mainly improve your sales rate. However, it can also be challenging and costly to your budget, especially when you are just starting.
            </p>

            <p>
              Every country has its own set of rules and regulations, tariffs and taxes, and requirements for any incoming shipments outside of their country. That is why it is very important to find a reliable fulfillment partner who can help you navigate international shipping and provide a budget-friendly shipping rate.
            </p>

            <h5>
              <strong>
                Freight shipping
              </strong>
            </h5>

            <p>
              Freight shipping is the transportation of cargo across the globe either through air, land, or sea. Larger than 30-inch shipment across all dimensions or weighs over 150 pounds will be considered freight.
            </p>

            <p>
              Ecommerce businesses use freight shipping to receive inventory from their manufacturer and send products to fulfillment centers or distributors.
            </p>

            <h5>
              <strong>
                Factors that affect shipping costs
              </strong>
            </h5>

            <p>
              Many online shoppers expect affordable and fast delivery whenever they shop online but keep in mind that you need to ensure that you’re offering affordable shipping rates for your business. That is why you need a well-considered and deliberate strategy. These factors play significant roles in your final shipping costs.
            </p>

            <h5>
              <strong>
                Weight and size
              </strong>
            </h5>

            <p>
              The size and weight of your products are a major influence on the costs for shipping. If all your products have a similar weight or size, your shipping strategy will be different if your products have various sizes and weights.
            </p>

            <Image src={PackagingImg} className="" alt="packaging materials"/>

            <h5>
              <strong>
                Packaging materials
              </strong>
            </h5>

            <p>
              Some products require extra protection to ship safely; others are temperature sensitive, fragile, or perishable. It is important to note that using special packaging and materials can affect your product shipping costs.
            </p>

            <Image src={ShippingDestinationImg} className="" alt="shipping destination"/>


            <h5>
              <strong>
                Shipping Destinations
              </strong>
            </h5>

            <p>
              Shipping destinations are as important as the product size and weight. Customers could be everywhere, especially when offering international shipping. It is highly recommended that your shipping costs are based on whether the order is from an international or domestic customer.
            </p>

            <h5>
              <strong>
                Flat rate shipping
              </strong>
            </h5>

            <p>
              Flat rate shipping means that certain products have a fixed cost shipping added to the product regardless of its order value. This type of shipping rate applies either across all orders or for all orders that fit a specific weight range or order value. 
            </p>

            <h5>
              <strong>
                Real-time carrier rates
              </strong>
            </h5>

            <p>
              The kind of shipping rates is automatically pulled from the carriers based on the customer’s location and delivery preferences giving them the most accurate shipping costs as possible.
            </p>

            <p>
              It allows you to ensure that you’re offering the cheapest possible shipping option by charging your customer exactly what the carrier charges you.
            </p>

            <h5>
              <strong>
                Free shipping
              </strong>
            </h5>

            <p>
              Arguably one of the simplest and most effective strategies to use is to offer free shipping. Customers love free shipping! Shoppers tend to purchase more products if free shipping is available.
            </p>

            <p>
              Offering free shipping can reduce shopping cart abandonment, increase conversion rates, and build customer loyalty. You can offer free shipping while staying profitable using this approach:
            </p>

            <UnorderedList>
              <li>
                Including the shipping cost in the product price
              </li>

              <li>
                Requiring a spend threshold in exchange for free shipping
              </li>
            </UnorderedList>

            <Image src={ExpressShippingImg} className="" alt="express shipping"/>

            <h5>
              <strong>
                Implement your shipping strategy
              </strong>
            </h5>

            <p>
              The key to having an effective shipping strategy is finding the right balance between your business’ and your customer’s needs. To consider all factors that can affect your shipping costs for each product, determining the best approach to ship without exhausting your budget, giving quality service to your customers. It is also essential to adapt your strategies as you learn more about your audience.
            </p>

            <p>
              Now that you’re equipped with the right formula to build an eCommerce shipping strategy that’s right for your business, consider making <a href="https://shipkoo.com/">Shipkoo</a> as a part of that strategy.
            </p>

            <p>
              Shipkoo is a one-stop solution provider offering global fulfillment with express shipping powered by advanced technologies and data analytics where you can focus more on growing your business.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;